import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Sky, Cloud, Html, Float, Text3D } from "@react-three/drei";
import { Model } from "./components/World";
import Flugantaj from "./components/Flugantaj";
import Melolo from "./components/Melolo";
import Goussepaille from "./components/Goussepaille";
import * as font3D from "./fonts/better.json";
import Tour1 from "./components/Tour1";
import Tour2 from "./components/Tour2";
import Cheese from "./components/Cheese";
import Spartacuisse from "./components/Spartacuisse";
import Infos from "./components/Infos";

function App() {
  const [hidden, set] = useState();

  return (
    <div className="canvas">
      <Canvas camera={{ position: [3.5, 0.5, -0.5] }}>
        <OrbitControls maxDistance={45} enablePan={false} position={[0, 4, 4]} />
        <directionalLight intensity={0.5} color="white" position={[-4, 10, 9]} />
        <directionalLight intensity={0.5} color="white" position={[1, 10, -9]} />
        {/* <Cloud
          opacity={0.5}
          speed={0.7} // Rotation speed
          width={50} // Width of the full cloud
          depth={1.5} // Z-dir depth
          segments={30} // Number of particles
        /> */}

        <Sky distance={450000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.55} />
        <ambientLight intensity={1.5} />
        <pointLight position={[10, 1, 1]} />
        <Float rotationIntensity={0.05} floatingRange={[-0.001, -0.005]}>
          <Model position={[-1, -6, -2]} scale={[5, 5, 5]} />
          <Flugantaj />
          <Melolo />
          <Goussepaille />
          <Spartacuisse />
          <Tour1 />
          <Tour2 />
          <Infos />
          <Cheese pos={[0, 1, 0]} />
          <Cheese pos={[0.5, 0.8, 0]} />
          <Cheese pos={[-0.5, 0.7, 1]} />
          <Cheese pos={[1, 0.5, -1]} />
        </Float>
      </Canvas>
    </div>
  );
}

export default App;
