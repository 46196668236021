import { Html } from "@react-three/drei";
import React, { useState, useEffect } from "react";

function Tour2() {
  const [hidden, set] = useState();
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);

  const [alive, setAlive] = useState(true);

  let cursorUrl = "url(https://www.closetdiscoqueen.pizza/listen.png)15 15, auto";

  useEffect(() => {
    document.body.style.cursor = active || hovered ? cursorUrl : "auto";
  }, [active]);

  const isHovered = () => {
    setActive(true);
    // setHovered(true);
  };
  const notHovered = () => {
    setActive(false);
    // setHovered(false);
  };

  function readDates() {
    const lecture = new SpeechSynthesisUtterance("hello");
    lecture.text =
      "26 NOVEMBRE NYON L'USINE À GAZ. - 09 DÉC SION POINT 11. - 10 DÉC TBC TBC. - 16 DÉC TBC TBC. - 17 DÉC SAIGNELÉGIER BFM. miaou wouaf miaou wouaf, wouaf wouaf wouaf wouaf wouaf wouaf wouaf wouaf wouaf wouaf wouaf wouaf miaou wouaf wouaf wouaf";
    lecture.lang = "fr";
    lecture.volume = 0.2;
    speechSynthesis.speak(lecture);
  }

  return (
    <Html
      wrapperClass="tour"
      as="div" // Wrapping element (default: 'div')
      distanceFactor={2} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
      zIndexRange={[1000000000, 0]} // Z-order range (default=[16777271, 0])
      sprite // Renders as sprite, but only in transform mode (default=false)
      occlude // Callback when the visibility changes (default: undefined)
      onOcclude={set}
      style={{
        transition: "all 0.2s",
        opacity: hidden ? 0 : 1,
      }}
      position={[0, 1, -3]}
    >
      <div onClick={readDates} onPointerOver={isHovered} onPointerOut={notHovered} className="div">
        <ul>
          <li>26 NOV NYON - L'USINE À GAZ</li>
          <li>09 DÉC SION - POINT 11</li>
          <li>10 DÉC TBC - TBC</li>
          <li>16 DÉC TBC - TBC</li>
          <li>17 DÉC SAIGNELÉGIER - BFM</li>
        </ul>
      </div>
    </Html>
  );
}
export default Tour2;
