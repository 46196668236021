import React, { useRef, useState, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
import HOMEMODEL from "../gltf/world.glb";
import { Float } from "@react-three/drei";
import jona from "../sounds/jona.mp3";
import chadi from "../sounds/chadi.mp3";
import luc from "../sounds/luc.mp3";
import kevin from "../sounds/kevin.mp3";

export function Model(props) {
  const { nodes, materials } = useGLTF(HOMEMODEL);
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  let cursorUrl = "url(https://www.closetdiscoqueen.pizza/bone2.png)25 25, auto";

  useEffect(() => {
    document.body.style.cursor = active || hovered ? cursorUrl : "auto";
  }, [active]);

  const isHovered = () => {
    setActive(true);
    // setHovered(true);
  };
  const notHovered = () => {
    setActive(false);
    // setHovered(false);
  };

  function aboieJona() {
    console.log("wouaf");
    let audio = new Audio(jona);
    audio.play();
  }
  function aboieChadi() {
    console.log("wouaf");
    let audio = new Audio(chadi);
    audio.play();
  }
  function aboieLuc() {
    console.log("wouaf");
    let audio = new Audio(luc);
    audio.play();
  }
  function aboieKevin() {
    console.log("wouaf");
    let audio = new Audio(kevin);
    audio.play();
  }

  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Object_37.geometry} material={materials.brush_Icing} />
          <mesh geometry={nodes.Object_11.geometry} material={materials.brush_DoubleTaperedMarker} />
          <mesh geometry={nodes.Object_5.geometry} material={materials.brush_MatteHull} />
          <mesh geometry={nodes.Object_9.geometry} material={materials.brush_Petal} />
          <mesh geometry={nodes.Object_7.geometry} material={materials.brush_Spikes} />
          <Float rotationIntensity={0.05} floatingRange={[0.001, 0.001]}>
            <mesh
              on
              onClick={aboieChadi}
              onPointerOver={isHovered}
              onPointerOut={notHovered}
              geometry={nodes.Object_14.geometry}
              material={materials.brush_MatteHull}
            />
          </Float>
          <Float rotationIntensity={0.05} floatingRange={[-0.001, -0.005]}>
            <mesh
              onClick={aboieLuc}
              onPointerOver={isHovered}
              onPointerOut={notHovered}
              geometry={nodes.Object_17.geometry}
              material={materials.brush_MatteHull}
            />
          </Float>
          <Float rotationIntensity={0.05} floatingRange={[0.008, 0.008]}>
            <mesh
              onClick={aboieKevin}
              onPointerOver={isHovered}
              onPointerOut={notHovered}
              geometry={nodes.Object_20.geometry}
              material={materials.brush_MatteHull}
            />
            <mesh geometry={nodes.Object_22.geometry} material={materials.brush_TaperedFlat} />
          </Float>
          <mesh geometry={nodes.Object_29.geometry} material={materials.brush_Flat} />
          <mesh geometry={nodes.Object_27.geometry} material={materials.brush_Marker} />
          <mesh
            onClick={aboieJona}
            onPointerOver={isHovered}
            onPointerOut={notHovered}
            geometry={nodes.Object_25.geometry}
            material={materials.brush_MatteHull}
          />
          <Float rotationIntensity={0.5} floatingRange={[0.001, 0.001]}>
            <mesh geometry={nodes.Object_32.geometry} material={materials.brush_Icing} />
          </Float>
          <Float rotationIntensity={0.5} floatingRange={[0.001, 0.001]}>
            <mesh geometry={nodes.Object_35.geometry} material={materials.brush_Icing} />
          </Float>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(HOMEMODEL);
