import { Html } from "@react-three/drei";
import React, { useState, useEffect } from "react";

function Tour1() {
  const [hidden, set] = useState();
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);

  const [alive, setAlive] = useState(true);

  let cursorUrl = "url(https://www.closetdiscoqueen.pizza/listen.png)15 15, auto";

  useEffect(() => {
    document.body.style.cursor = active || hovered ? cursorUrl : "auto";
  }, [active]);

  const isHovered = () => {
    setActive(true);
    // setHovered(true);
  };
  const notHovered = () => {
    setActive(false);
    // setHovered(false);
  };

  function readDates() {
    const lecture = new SpeechSynthesisUtterance("hello");
    lecture.text =
      "10 SEPT ROSSINIERE LE BOUGNON - 23 SEPT GENEVE PTR L'USINE - 24 SEPT KREUZLINGEN HORST KLUB - 01 OCT NEUCHÂTEL CASE À CHOCS - 15 OCT ZÜRICH ROTE FABRIK. - A tout bientôt les amis wouaf wouaf";
    lecture.lang = "fr";
    lecture.volume = 0.2;
    speechSynthesis.speak(lecture);
  }

  return (
    <Html
      wrapperClass="tour"
      as="div" // Wrapping element (default: 'div')
      distanceFactor={2} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
      zIndexRange={[100000, 0]} // Z-order range (default=[16777271, 0])
      sprite // Renders as sprite, but only in transform mode (default=false)
      occlude // Callback when the visibility changes (default: undefined)
      onOcclude={set}
      style={{
        transition: "all 0.2s",
        opacity: hidden ? 0 : 1,
      }}
      position={[0, 1, 3]}
    >
      <div onClick={readDates} onPointerOver={isHovered} onPointerOut={notHovered} className="div">
        <ul>
          <li>10 SEPT ROSSINIERE - LE BOUGNON</li>
          <li>23 SEPT GENEVE PTR - L'USINE</li>
          <li>24 SEPT KREUZLINGEN - HORST KLUB</li>
          <li>01 OCT NEUCHÂTEL - CASE À CHOCS</li>
          <li>15 OCT ZÜRICH - ROTE FABRIK</li>
        </ul>
      </div>
    </Html>
  );
}
export default Tour1;
