import React, { useState } from "react";
import { Html } from "@react-three/drei";

function Melolo() {
  const [hidden, set] = useState();

  return (
    <Html
      as="div" // Wrapping element (default: 'div')
      distanceFactor={0.4} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
      zIndexRange={[1000000000, 0]} // Z-order range (default=[16777271, 0])
      sprite // Renders as sprite, but only in transform mode (default=false)
      occlude // Callback when the visibility changes (default: undefined)
      onOcclude={set}
      style={{
        transition: "all 0.2s",
        opacity: hidden ? 0 : 1,
      }}
      position={[2, -0.15, 1]}
    >
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/_CMfmS1zjFA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </Html>
  );
}

export default Melolo;
