import { Html, Float } from "@react-three/drei";
import React, { useState, useEffect } from "react";
import cheese from "../img/cheese.png";
import boum from "../sounds/boum.mp3";

function Cheese({ pos }) {
  const [hidden, set] = useState();
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);

  const [alive, setAlive] = useState(true);

  let cursorUrl = "url(https://www.closetdiscoqueen.pizza/gun2.png)15 15, auto";

  useEffect(() => {
    document.body.style.cursor = active || hovered ? cursorUrl : "auto";
  }, [active]);

  const isHovered = () => {
    setActive(true);
    // setHovered(true);
  };
  const notHovered = () => {
    setActive(false);
    // setHovered(false);
  };

  function killKenny() {
    setAlive(false);
    setActive(false);
    let audio = new Audio(boum);
    audio.play();
  }

  return (
    <Float rotationIntensity={2} floatingRange={[0, 3]}>
      <mesh>
        {alive && (
          <Html
            wrapperClass="cheese"
            as="div" // Wrapping element (default: 'div')
            zIndexRange={[10000000000, 0]} // Z-order range (default=[16777271, 0])
            occlude // Callback when the visibility changes (default: undefined)
            onOcclude={set}
            distanceFactor={1}
            style={{
              transition: "all 0.2s",
              opacity: hidden ? 0 : 1,
            }}
            position={pos}
          >
            <img onClick={killKenny} onPointerOver={isHovered} onPointerOut={notHovered} src={cheese} alt="" />
          </Html>
        )}
      </mesh>
    </Float>
  );
}
export default Cheese;
