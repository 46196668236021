import { Html } from "@react-three/drei";
import React, { useState } from "react";

function Infos() {
  const [hidden, set] = useState();

  return (
    <Html
      wrapperClass="tour"
      as="div" // Wrapping element (default: 'div')
      distanceFactor={2} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
      zIndexRange={[100000, 0]} // Z-order range (default=[16777271, 0])
      sprite // Renders as sprite, but only in transform mode (default=false)
      occlude // Callback when the visibility changes (default: undefined)
      onOcclude={set}
      style={{
        transition: "all 0.2s",
        opacity: hidden ? 0 : 1,
      }}
      position={[-2, 0.5, 1]}
    >
      <div className="div">
        <a href="mailto:contact@closetdiscoqueen.pizza">CONTACT</a> <br />
        <a href="mailto:martina@gladwemet.ch">BOOKING</a> <br />
        <a href="https://hummus-records.com/product-tag/closet-disco-queen/" target="_blank">
          SHOP
        </a>
      </div>
    </Html>
  );
}
export default Infos;
